<template>
  <b-card class="shop-profile">
    <b-row>
       <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label
            class="form-extra-label"
          >{{ $t('Product Type') }} <span>({{ $t('Your Product Type') }})</span></label>
          <b-form-select
            v-model="storeDetails.product_type"
            :class="{ 'is-invalid': !storeDetails.product_type }"
            :options="productTypeOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label
            class="form-extra-label"
          >{{ $t('Shop Type') }} <span>({{ $t('Your store category') }})</span></label>
          <b-form-select
            v-model="storeDetails.store_type_id"
            :class="{ 'is-invalid': !storeDetails.store_type_id }"
            :options="shopTypeOptions"
            :disabled="isEmpty(storeDetails.product_type)"
            @change="getStoreCuisines"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label
            class="form-extra-label"
          >{{ $t('Shop Name') }} <span>({{ $t('Your store name') }})</span></label>
          <b-form-input
            v-model="storeDetails.store_name"
            :class="{ 'is-invalid': !storeDetails.store_name }"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="isFoodCategory && !isNotOthers"
        cols="12"
        md="6"
        class="no-margin-radio"
      >
        <b-form-group :label="$t('Select Store Category')">
          <b-form-radio-group
            v-model="storeDetails.is_veg"
            :options="[
              { text: 'Pure Veg', value: 'Pure Veg', disabled: false },
              { text: 'Non Veg', value: 'Non Veg', disabled: false },
            ]"
            class="demo-inline-spacing mb-1"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="storeProfile.cuisine_ids && storeProfile.cuisine_data"
        cols="12"
        md="6"
      >
        <b-form-group :label="$t('Select Category')">
          <multiselect
            v-model="storeProfile.cuisine_ids"
            label="name"
            track-by="id"
            :options="cuisineOptions"
            :multiple="true"
            :close-on-select="false"
            :taggable="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Email') }} <span>({{ $t('Your store Email ID') }})</span></label>
          <b-form-input
            v-model="storeDetails.email"
            :class="{ 'is-invalid': !storeDetails.email }"
            type="email"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Location') }} <span>({{ $t('Your store location') }})</span></label>
          <GmapAutocomplete
            class="form-control"
            :value="storeDetails.store_location"
            :class="{ 'is-invalid': !storeDetails.store_location }"
            @place_changed="setPlace"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Country Name') }} <span>({{ $t('Your store country') }})</span></label>
          <model-select
            v-model="storeDetails.country_id"
            :isDisabled="isCountrySet"
            :class="{ 'is-invalid': !storeDetails.country_id }"
            :options="countryOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('State Name') }} <span>({{ $t('Your store state') }})</span></label>
          <model-select
            v-model="storeDetails.state_id"
            :class="{ 'is-invalid': !storeDetails.state_id }"
            :options="stateOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('City Name') }} <span>({{ $t('Your store city') }})</span></label>
          <model-select
            v-model="storeDetails.city_id"
            :class="{ 'is-invalid': !storeDetails.city_id }"
            :options="cityTypeOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Zipcode') }} <span>({{ $t('Your store zip code') }})</span></label>
          <b-form-input
            v-model="storeDetails.store_zipcode"
            :class="{ 'is-invalid': !storeDetails.store_zipcode }"
            type="number"
          />
        </b-form-group>
      </b-col>
      <!-- <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Zone') }} <span>({{ $t('Your store zone') }})</span></label>
          <b-form-select
            v-model="storeDetails.zone_id"
            disabled
            :options="zoneOptions"
          />
        </b-form-group>
      </b-col> -->
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label class="form-extra-label">{{ $t('Min Amount') }} <span>(Minimum order value for delivery)</span></label>
          <b-form-input
            v-model="storeDetails.offer_min_amount"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Offer Percentage') }}(%) <span>({{ $t('Discount percentage on order') }})</span></label>
          <b-form-input
            v-model="storeDetails.offer_percent"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Tax Percentage') }}(%) <span>({{ $t('Chargeable Tax percentage for orders') }})</span></label>
          <b-form-input
            v-model="storeDetails.store_gst"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Commission Percentage') }}(%) <span>({{ $t('Commission charged on order') }})</span></label>
          <b-form-input
            v-model="storeDetails.commission"
            disabled
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Contact Person') }} <span>({{ $t('Your store contact person') }})</span></label>
          <b-form-input
            v-model="storeDetails.contact_person"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Description') }} <span>({{ $t('A brief about your store') }})</span></label>
          <b-form-input
            v-model="storeDetails.description"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
        style="z-index: 9;"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Contact Number') }} <span>({{ $t('Your store contact number') }})</span></label>
          <VuePhoneNumberInput
            ref="countryCodeRef"
            v-model="storeDetails.contact_number"
            :class="{ 'is-invalid': !storeDetails.contact_number }"
            placeholder="+91 0000000000"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="storeDetails.product_type == 1"
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Packing Charges') }} <span>({{ $t('Packing charges for the order') }})</span></label>
          <b-form-input
            v-model="storeDetails.store_packing_charges"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="storeDetails.product_type == 1"
        cols="12"
        md="4"
        class="d-flex align-items-center"
      >
        <b-form-checkbox
          v-model="storeDetails.free_delivery"
          name="free_delivery"
          switch
          inline
        >
          <label class="form-extra-label">
            {{ $t('Free Delivery') }} <span>({{ $t('Enable if you want to provide free delivery') }})</span></label>
        </b-form-checkbox>
      </b-col>
      <b-col
        v-if="storeDetails.product_type == 1"
        cols="12"
        md="4"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Free Delivery Limit') }} <span>({{ $t('Minimum order value required for free delivery') }})</span></label>
          <b-form-input
            v-model="storeDetails.free_delivery_limit"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="storeDetails.product_type == 1"
        cols="12"
        md="4"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Price Range') }}</label>
          <b-form-select
            v-model="storeDetails.price_range"
            :options="priceRangeOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group :label="$t('Language')">
          <b-form-select
            v-model="storeDetails.language"
            :options="$superApp.appConstants.languageList()"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Status') }} <span>({{ $t('Enable/Disable your store') }})</span></label>
          <b-form-select
            v-model="storeDetails.status"
            :options="shopStatus"
          />
        </b-form-group>
      </b-col>
      <!-- <b-col cols="12" md="6">
        <b-form-group label="">
          <label class="form-extra-label">Shop Response Time (Secs) <span>()</span></label>
          <b-form-input v-model="storeDetails.store_response_time" type="number" />
        </b-form-group>
      </b-col> -->
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label class="form-extra-label">{{ $t('Best Seller Count') }}</label>
          <b-form-input
            v-model="storeDetails.bestseller"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label class="form-extra-label">{{ $t('Best Seller Timeline (In Month)') }}</label>
          <b-form-input
            v-model="storeDetails.bestseller_month"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="storeDetails.product_type == 1"
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Prepare Time') }} <span>({{ $t('Order preparation time') }})</span></label>
          <div class="d-flex">
            <b-form-input
              v-model="storeDetails.prepare_time"
              type="number"
              style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important"
            />
            <b-form-select
              v-model="storeDetails.prepare_time_type"
              style="border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important"
              :options="prepareTimeType"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col
        v-if="storeDetails.product_type == 1"
        cols="12"
        md="6"
      >
        <b-form-group label="">
          <label
            class="form-extra-label"
          >{{ $t('Estimated Delivery Time') }} <span>({{ $t('Order delivery time') }})</span></label>
          <div class="d-flex">
            <b-form-input
              v-model="storeDetails.estimated_delivery_time"
              type="number"
              style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important"
            />
            <b-form-select
              v-model="storeDetails.estimated_delivery_time_type"
              style="border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important"
              :options="prepareTimeType"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col
        v-if="storeDetails.settings"
        cols="12"
        md="12"
      >
        <figure>
          <legend class="text-uppercase">
            {{ $t('Business Information') }}
          </legend>
          <div class="row">
            <b-col
              v-if="storeDetails.settings"
              cols="12"
              md="6"
            >
              <b-form-group label="">
                <label class="form-extra-label">{{ $t('Registration Id') }}</label>
                <b-form-input
                  v-model="storeDetails.settings.registration_id"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="storeDetails.settings"
              cols="12"
              md="6"
            >
              <b-form-group label="">
                <label class="form-extra-label">{{ $t('Tax Id') }}</label>
                <b-form-input
                  v-model="storeDetails.settings.tax_id"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="storeDetails.settings && storeDetails.product_type == 1"
              cols="12"
              md="6"
            >
              <b-form-group label="">
                <label class="form-extra-label">{{ $t('FSSAI No') }}</label>
                <b-form-input
                  v-model="storeDetails.settings.fsaai_no"
                  type="text"
                />
              </b-form-group>
            </b-col>
          </div>
        </figure>
      </b-col>
      <b-col
        v-if="storeDetails.product_type == 1"
        cols="12"
        md="12"
        class="mb-1"
      >
        <figure>
          <legend class="text-uppercase">
            {{ $t('Services') }}
          </legend>
          <div class="row">
            <b-col
              v-if="isFoodCategory"
              cols="12"
              md="3"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="storeDetails.dine_in"
                name="dine_in"
                switch
                inline
              >
                <label
                  class="form-extra-label"
                >{{ $t('Dine In') }} <span>({{ $t('Enable if your store provides Dine In option') }})</span></label>
              </b-form-checkbox>
            </b-col>
            <b-col
              v-if="isFoodCategory"
              cols="12"
              md="3"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="storeDetails.book_a_table"
                name="book_a_table"
                switch
                inline
              >
                <label
                  class="form-extra-label"
                >{{ $t('Table Booking') }} <span>({{ $t('Enable if you provide Table Booking option') }})</span></label>
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="storeDetails.pickup"
                name="pickup"
                switch
                inline
              >
                <label
                  class="form-extra-label"
                >{{ $t('Pickup') }} <span>({{ $t('Enable if your store provides take away option') }})</span></label>
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="storeDetails.delivery"
                name="delivery"
                switch
                inline
              >
                <label
                  class="form-extra-label"
                >{{ $t('Delivery') }} <span>({{ $t('Enable if your store provides delivery option') }})</span></label>
              </b-form-checkbox>
            </b-col>
          </div>
        </figure>
      </b-col>
      <b-col
        v-if="storeDetails.settings && storeDetails.product_type == 1"
        cols="12"
        md="12"
        class="mb-1"
      >
        <figure>
          <legend class="text-uppercase">
            {{ $t('Payment Options') }}
          </legend>
          <div class="row">
            <b-col
              v-if="storeDetails.settings"
              cols="12"
              md="4"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="storeDetails.settings.cash"
                name="cash"
                switch
                inline
              >
                <label
                  class="form-extra-label"
                >{{ $t('Cash') }} <span>({{ $t('Enable if your store provides cash on delivery') }})</span></label>
              </b-form-checkbox>
            </b-col>
            <b-col
              v-if="storeDetails.settings"
              cols="12"
              md="4"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="storeDetails.settings.card"
                name="card"
                switch
                inline
              >
                <label
                  class="form-extra-label"
                >{{ $t('Card') }} <span>({{ $t('Enable if your store accepts card payment') }})</span></label>
              </b-form-checkbox>
            </b-col>
            <b-col
              v-if="storeDetails.settings && storeDetails.product_type == 1"
              cols="12"
              md="4"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="storeDetails.settings.card_on_delivery"
                name="card_on_delivery"
                switch
                inline
              >
                <label
                  class="form-extra-label"
                >{{ $t('Card On Delivery') }} <span>({{ $t('Enable if your store accepts card payment') }})</span></label>
              </b-form-checkbox>
            </b-col>
          </div>
        </figure>
      </b-col>
      <b-col
        v-if="storeDetails.product_type == 1"
        cols="12"
        md="12"
        class="mb-1"
      >
        <figure>
          <legend class="text-uppercase">
            {{ $t('Additional Settings') }}
          </legend>
          <div class="row">
            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="misl_settings.auto_update_pos_order"
                name="auto_update_pos_order"
                switch
                inline
              >
                <label
                  class="form-extra-label"
                >{{ $t('Auto update POS order') }} <span>({{ $t('Auto update POS order') }})</span></label>
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="misl_settings.auto_accept_all_orders"
                name="auto_accept_all_orders"
                switch
                inline
              >
                <label
                  class="form-extra-label"
                >{{ $t('Auto accept all Orders') }} <span>({{ $t('Auto accept all Orders') }})</span></label>
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center"
            >
              <b-form-checkbox
                v-model="misl_settings.auto_accept_without_item_orders"
                name="auto_accept_without_item_orders"
                switch
                inline
              >
                <label
                  class="form-extra-label"
                >{{ $t('Auto accept without Orders') }} <span>({{ $t('Auto accept without item Orders') }})</span></label>
              </b-form-checkbox>
            </b-col>
          </div>
        </figure>
      </b-col>
      <b-col
        v-if="storeDetails.product_type == 1"
        cols="12"
        md="12"
        class="mb-1"
      >
        <figure>
          <legend class="text-uppercase">
            {{ $t('Timing') }}
          </legend>
          <div class="row">
            <b-col
              v-if="storeDetails.everydayTimesheet && storeDetails.everydayTimesheet[0]"
              cols="12"
              md="3"
              class="d-flex align-items-center mb-1"
            >
              <b-form-checkbox
                v-model="storeDetails.everydayTimesheet[0].open_all_time"
                name="open_all_time"
                switch
                inline
              >
                <span>{{ $t('Accept request 24x7') }}</span>
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center mb-1"
            >
              <b-form-checkbox
                v-model="storeDetails.everyday"
                name="everyday"
                switch
                inline
                @change="openingTimeChanged"
              >
                <label
                  class="form-extra-label"
                >{{ $t('Store Timing') }} <span>({{ $t('Set specific time for shop opening') }})</span></label>
              </b-form-checkbox>
            </b-col>
          </div>
        </figure>
      </b-col>
    </b-row>
    <div v-if="!showTimings && storeDetails.product_type == 1">
      <b-row
        v-for="(item, index) of storeDetails.everydayTimesheet"
        :key="index"
        :class="{ 'mt-2': index === 0 }"
      >
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="item.store_enabled"
            :name="item.store_day"
            switch
            inline
          >
            <span>{{ item.store_day === 'ALL' ? 'Everyday (Apply same timing for all days)' : item.store_day }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Opens at')">
            <b-form-timepicker
              v-model="item.store_start_time"
              locale="en"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Closes at')">
            <b-form-timepicker
              v-model="item.store_end_time"
              locale="en"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div v-if="showTimings">
      <b-row
        v-for="(item, index) of storeDetails.dailySheet"
        :key="index"
        :class="{ 'mt-2': index === 0 }"
      >
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="item.store_enabled"
            :name="item.store_day"
            switch
            inline
          >
            <span>{{ item.store_day }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Opens at')">
            <b-form-timepicker
              v-model="item.store_start_time"
              locale="en"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Closes at')">
            <b-form-timepicker
              v-model="item.store_end_time"
              locale="en"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-col
      cols="12"
      md="6"
    >
      <b-form-group :label="$t('Picture')">
        <image-input
          class="ml-0"
          :default-image="storeDetails.picture"
          @input-file="inputFile"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="12"
      class="text-center"
    >
      <button
        class="btn btn-primary"
        @click="saveStoreData"
      >
        {{ $t('Update Details') }}
      </button>
    </b-col>
  </b-card>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import { ModelSelect } from 'vue-search-select'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import Multiselect from 'vue-multiselect'
import eventBus from '@/libs/event-bus'
import { useStoreUi } from '../useStore'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import 'vue-search-select/dist/VueSearchSelect.css'
import ImageInput from '../../users/shared/ImageInput.vue'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { useUserUi } from '@/views/users/useUser'
import { mapMutations } from 'vuex'
import { isEmpty } from '@/utilities'

const productTypeOptions = [
  {
    text: 'Select One',
    value: null,
    disabled: true
  },
  {
    text: 'Physical Product',
    value: 1,
  },
  {
    text: 'Digital Product',
    value: 2,
  }
]
export default {
  components: {
    Multiselect,
    VuePhoneNumberInput,
    ModelSelect,
    ImageInput,
  },
  data() {
    return {
      productTypeOptions,
      isCountrySet: false,
      userSelectedPlace: null,
      storeList: [],
      storeDetails: {},
      storeProfile: {
        cuisine_ids: [],
        cuisine_data: [],
      },
      showTimings: false,
      countryList: [],
      cityTypeOptions: [],
      countryOptions: [],
      stateOptions: [],
      priceRangeOptions: [
        {
          text: 'Select Price Range',
          value: '',
        },
        {
          text: 'Not to Say',
          value: 'NOT_TO_SAY',
        },
        {
          text: 'In-Expensive',
          value: 'INEXPENSIVE',
        },
        {
          text: 'Moderate',
          value: 'MODERATE',
        },
        {
          text: 'Pricey',
          value: 'PRICEY',
        },
        {
          text: 'Ultra High',
          value: 'ULTRA_HIGH',
        },
      ],
      misl_settings: {
        auto_update_pos_order: false,
        auto_accept_all_orders: false,
        auto_accept_without_item_orders: false,
        auto_assign_retry_attempts: 3,
        auto_assign_retry_duration: 5
      }
    }
  },
  computed: {
    isFoodCategory() {
      const storeType = this.storeList.find(x => x.id === this.storeDetails.store_type_id)
      if (!storeType) return false
      return storeType.category === 'FOOD'
    },
    storeInfo() {
      return window.SuperApp.getters.userInfo()
    },
    shopTypeOptions() {
      if(isEmpty(this.storeDetails.product_type)) return [];
      if(this.storeDetails.product_type != 1 && this.storeDetails.product_type != 2) return [];
      return this.storeList.filter(i => {
        if(this.storeDetails.product_type == 1) return i.category != 'DIGITAL'
        if(this.storeDetails.product_type == 2) return i.category == 'DIGITAL'
        return []
      }).map(x => ({
        text: x.name,
        value: x.id,
      }))
    },
    prepareTimeType() {
      return [
        {
          text: 'Minutes',
          value: 'MINUTES',
        },
        {
          text: 'Hours',
          value: 'HOURS',
        },
        {
          text: 'Day',
          value: 'DAYS',
        },
      ]
    },
    zoneOptions() {
      if (!this.storeDetails.zone_data) return []
      return this.storeDetails.zone_data.map(x => ({
        text: x.name,
        value: x.id,
      }))
    },
    shopStatus() {
      return [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'Onboarding',
          value: 0,
        },
        {
          text: 'Banned',
          value: 2,
        },
      ]
    },
    cuisineOptions() {
      if (!this.storeDetails.cuisine_data) return []
      return this.storeDetails.cuisine_data.map(x => ({
        id: x.id,
        name: x.name,
      }))
    },
    isNotOthers() {
      const storeType = this.storeList.find(x => x.id === this.storeDetails.store_type_id)
      if (!storeType) return false
      return storeType.category === 'OTHERS'
    },
  },
  watch: {
    'storeDetails.product_type': {
      immediate: true,
      deep: true,
      handler(nv, ov) {
        if(!isEmpty(ov) && !isEmpty(nv) && nv != ov){
          this.storeDetails.store_type_id = null
        }
      },
    },
    'storeDetails.country_id': {
      immediate: true,
      deep: true,
      handler() {
        if (this.storeDetails && this.storeDetails.country_id) {
          this.onCountryChange()
        }
      },
    },
    'storeDetails.state_id': {
      immediate: true,
      deep: true,
      handler() {
        if (this.storeDetails && this.storeDetails.state_id) {
          this.onStateChange()
        }
      },
    },
  },
  mounted() {
    this.getStoreList()
    this.getStoreInfo()
    this.getCountryList()
  },
  methods: {
    ...mapMutations('subscription', ['importFromUser']),
    isEmpty,
    getCountryList() {
      const { countryList } = useUserUi()
      countryList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.countryOptions = data.responseData.map(x => ({
              text: x.country_name,
              value: x.id,
            }))
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    onStateChange() {
      this.cityTypeOptions = []
      const { getCityByStateCountry } = useUserUi()
      showLoader()
      getCityByStateCountry(`country_id=${this.storeDetails.country_id}&state_id=${this.storeDetails.state_id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.cityTypeOptions = data.responseData.map(x => ({
              text: x.city_name,
              value: x.id,
            }))
            // eslint-disable-next-line eqeqeq
            if (!data.responseData.find(x => x.id == this.storeDetails.city_id)) {
              this.storeDetails.city_id = ''
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
          if (this.userSelectedPlace && this.userSelectedPlace.setCity) {
            this.setCityFromApi()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    onCountryChange() {
      this.cityTypeOptions = []
      this.stateOptions = []
      const { getStateByCountry } = useUserUi()
      showLoader()
      getStateByCountry(this.storeDetails.country_id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.stateOptions = data.responseData.map(x => ({
              text: x.state_name,
              value: x.id,
            }))
            // eslint-disable-next-line eqeqeq
            if (!data.responseData.find(x => x.id == this.storeDetails.state_id)) {
              this.storeDetails.state_id = ''
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
          if (this.userSelectedPlace && this.userSelectedPlace.setState) {
            this.setStateFromApi()
            this.userSelectedPlace.setState = false
          }
          if (this.storeDetails.state_id) {
            this.onStateChange()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    setPlace(place) {
      this.userSelectedPlace = place
      if (Array.isArray(place.address_components)) {
        const components = place.address_components.filter(x => Array.isArray(x.types))
        const postalCode = components.find(x => x.types.includes('postal_code'))
        if (postalCode) {
          this.storeDetails.store_zipcode = postalCode.long_name
        }
        const country = components.find(x => x.types.includes('country'))
        if (country) {
          const findCountry = this.countryOptions.find(x => x.text === country.long_name)
          if (findCountry) {
            this.storeDetails.country_id = findCountry.value
            this.onCountryChange()
          }
        }
        this.storeDetails.store_location = place.formatted_address
        this.userSelectedPlace.setState = true
        this.userSelectedPlace.setCity = true
      }
    },
    setStateFromApi() {
      const components = this.userSelectedPlace.address_components.filter(x => Array.isArray(x.types))
      const state = components.find(x => x.types.includes('administrative_area_level_1'))
      if (state) {
        const findState = this.stateOptions.find(x => x.text === state.long_name)
        if (findState) {
          this.storeDetails.state_id = findState.value
        }
      }
    },
    setCityFromApi() {
      const components = this.userSelectedPlace.address_components.filter(x => Array.isArray(x.types))
      const city = components.find(x => x.types.includes('locality'))
      if (city) {
        const findCity = this.cityTypeOptions.find(x => x.text === city.long_name)
        if (findCity) {
          this.storeDetails.city_id = findCity.value
        }
      }
    },
    inputFile(payload) {
      if (this.storeDetails) this.storeDetails.picture = payload
    },
    openingTimeChanged() {
      if (!this.storeDetails.everyday) {
        this.showTimings = false
      } else {
        this.showTimings = true
      }
    },
    getStoreList() {
      const { getStoreTypeList } = useStoreUi()

      getStoreTypeList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.storeList = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getStoreCuisines() {
      showLoader()
      const { storeCuisines } = useUserUi()
      storeCuisines(this.storeDetails.store_type_id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.storeDetails.cui_selectdata = []
            this.storeProfile.cuisine_data = data.responseData
            this.storeDetails.cuisine_data = data.responseData
            this.storeProfile.cuisine_ids = this.storeDetails.cuisine_data
              .filter(x => this.storeDetails.cui_selectdata.includes(x.id))
              .map(x => ({
                id: x.id,
                name: x.name,
              }))
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getStoreTIme(key, tag) {
      return this.storeDetails.time_data.find(x => x.store_day === key) ? this.storeDetails.time_data.find(x => x.store_day === key)[tag] : '00:00'
    },
    isTimeEnabled(key) {
      return Boolean(this.storeDetails.time_data.find(x => x.store_day === key))
    },
    getStoreInfo() {
      showLoader()
      const { storeProfile } = useStoreUi()

      storeProfile(this.storeInfo.id)
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            this.importFromUser(data.responseData)
            if (!data.responseData.state_id) {
              // eslint-disable-next-line no-param-reassign
              data.responseData.state_id = ''
            }
            if(!isEmpty(data?.responseData?.country_id)){
              this.isCountrySet = true
            }
            this.storeDetails = data.responseData
            if (!this.storeDetails.cuisine_data) {
              this.storeDetails.cuisine_data = []
            }
            if (!this.storeDetails.cui_selectdata) {
              this.storeDetails.cui_selectdata = []
            }
            this.storeProfile.cuisine_ids = this.storeDetails.cuisine_data
              .filter(x => this.storeDetails.cui_selectdata.includes(x.id))
              .map(x => ({
                id: x.id,
                name: x.name,
              }))
            window.SuperApp.getters.setUserInfo(JSON.stringify(this.storeDetails))
            eventBus.$emit('set-user-info-force')
            this.storeDetails.picture = window.SuperApp.helper.image(this.storeDetails.picture)
            window.storeDetails = this.storeDetails
            if (!this.storeDetails.settings) {
              this.storeDetails.settings = {}
            }
            this.storeDetails.settings.card_on_delivery = Boolean(this.storeDetails.settings.card_on_delivery)
            this.storeDetails.settings.card = Boolean(this.storeDetails.settings.card)
            this.storeDetails.settings.cash = Boolean(this.storeDetails.settings.cash)

            this.storeDetails.force_assign = Boolean(this.storeDetails.force_assign)
            this.storeDetails.free_delivery = Boolean(this.storeDetails.free_delivery)
            this.storeDetails.third_party = Boolean(this.storeDetails.third_party)
            this.storeDetails.own_agents = Boolean(this.storeDetails.own_agents)

            this.storeDetails.delivery = Boolean(this.storeDetails.delivery)
            this.storeDetails.dine_in = Boolean(this.storeDetails.dine_in)
            this.storeDetails.book_a_table = Boolean(this.storeDetails.book_a_table)
            if(!isEmpty(this.storeDetails.misl_settings)){
              this.misl_settings = JSON.parse(this.storeDetails.misl_settings);
            }
            this.storeDetails.pickup = Boolean(this.storeDetails.pickup)

            if (this.storeDetails.time_data && this.storeDetails.time_data.length > 0 && this.storeDetails.time_data[0].store_day !== 'ALL') {
              this.storeDetails.everyday = true
            } else {
              this.storeDetails.everyday = false
            }
            this.storeDetails.everydayTimesheet = [
              {
                store_day: 'ALL',
                store_end_time: this.getStoreTIme('ALL', 'store_end_time'),
                store_start_time: this.getStoreTIme('ALL', 'store_start_time'),
                store_enabled: true,
                open_all_time: this.storeDetails.time_data && this.storeDetails.time_data[0] && this.storeDetails.time_data[0].open_all_time === 1,
              },
            ]
            const dayKeys = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
            this.storeDetails.dailySheet = dayKeys.map(x => ({
              store_day: x,
              store_end_time: this.getStoreTIme(x, 'store_end_time'),
              store_start_time: this.getStoreTIme(x, 'store_start_time'),
              store_enabled: this.isTimeEnabled('ALL') ? true : this.isTimeEnabled(x),
            }))
            this.openingTimeChanged()
            this.onCountryChange()
            setTimeout(() => {
              if (this.storeDetails.country_code) {
                window.SuperApp.helper.setCountryCode(this.$refs.countryCodeRef, this.storeDetails.country_code, this.storeDetails.contact_number)
              }
            }, 250)
            this.$emit('profile-details', this.storeDetails)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    saveStoreData() {
      if (window.SuperApp.validations.anyErrorInModal(false)) {
        showDangerNotification(this, 'Please fill the required details')
        return
      }
      let updateField = [
        'id',
        'store_type_id',
        'product_type',
        'store_name',
        'email',
        'offer_min_amount',
        'estimated_delivery_time',
        'offer_percent',
        'price_range',
        'is_veg',
        'store_gst',
        'country_id',
        'city_id',
        'contact_person',
        'description',
        'contact_number',
        'store_location',
        'store_packing_charges',
        'free_delivery_limit',
        'language',
        'zone_id',
        'store_zipcode',
        'status',
        'store_response_time',
        'bestseller',
        'bestseller_month',
        'third_party_url',
        'prepare_time',
        'prepare_time_type',
        'estimated_delivery_time_type',
        'country_code',
        'iso2',
        'flat_no',
        'street',
        'picture',
        'state_id',
        'facebook',
        'telegram',
        'whatsapp',
        'instagram',
        'linkedin',
        'twitter',
        'viber',
        'youtube',
        'messenger',
        'pintrest',
        'skype',
        'tiktok',
      ]
      const formData = new FormData()
      if (this.userSelectedPlace) {
        formData.append('store_location', this.userSelectedPlace.formatted_address)
        formData.append('longitude', this.userSelectedPlace.geometry.location.lng())
        formData.append('latitude', this.userSelectedPlace.geometry.location.lat())
      } else {
        formData.append('store_location', this.storeDetails.store_location)
        formData.append('longitude', this.storeDetails.longitude)
        formData.append('latitude', this.storeDetails.latitude)
      }
      const countryCode = this.$refs.countryCodeRef.codesCountries.find(x => x.iso2 === this.$refs.countryCodeRef.countryCode)
      if (countryCode) {
        this.storeDetails.country_code = countryCode.dialCode
      }
      updateField = Array.from(new Set(updateField))
      updateField.forEach(x => {
        if (typeof this.storeDetails[x] === 'boolean') {
          formData.append(x, this.storeDetails[x] ? 1 : 0)
          // eslint-disable-next-line eqeqeq
        } else if (this.storeDetails[x] != undefined) {
          formData.append(x, this.storeDetails[x] ? this.storeDetails[x] : '')
        }
      })
      this.storeProfile.cuisine_ids.forEach(item => {
        formData.append('cuisine_id[]', item.id)
      })
      if (this.storeDetails.everydayTimesheet) {
        formData.append('hours_opening[ALL]', this.storeDetails.everydayTimesheet[0].store_start_time)
        formData.append('hours_closing[ALL]', this.storeDetails.everydayTimesheet[0].store_end_time)
        formData.append('open_all_time', Number(this.storeDetails.everydayTimesheet[0].open_all_time))
        if (!this.storeDetails.everyday) {
          formData.append('everyday', 1)
          if (this.storeDetails.everydayTimesheet[0].store_enabled) formData.append('day[ALL]', 'ALL')
        }
      }
      const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
      days.forEach((x, i) => {
        if (this.storeDetails.dailySheet[i]) {
          if (this.storeDetails.everyday && this.storeDetails.dailySheet[i].store_enabled) {
            formData.append(`day[${x}]`, this.storeDetails.dailySheet[i].store_day)
          }
          formData.append(`hours_opening[${x}]`, this.storeDetails.dailySheet[i].store_start_time)
          formData.append(`hours_closing[${x}]`, this.storeDetails.dailySheet[i].store_end_time)
        }
      })
      formData.append('_method', 'PATCH')
      formData.append('force_assign', Number(this.storeDetails.force_assign))
      formData.append('free_delivery', Number(this.storeDetails.free_delivery))
      formData.append('third_party', Number(this.storeDetails.third_party))
      formData.append('own_agents', Number(this.storeDetails.own_agents))
      formData.append('card_on_delivery', Number(this.storeDetails.settings.card_on_delivery))
      formData.append('card', Number(this.storeDetails.settings.card))
      formData.append('cash', Number(this.storeDetails.settings.cash))
      formData.append('fsaai_no', this.storeDetails.settings.fsaai_no ? this.storeDetails.settings.fsaai_no : '')
      formData.append('tax_id', this.storeDetails.settings.tax_id ? this.storeDetails.settings.tax_id : '')
      formData.append('registration_id', this.storeDetails.settings.registration_id ? this.storeDetails.settings.registration_id : '')
      formData.append('service_radius', this.storeDetails.settings.service_radius ? this.storeDetails.settings.service_radius : '')
      formData.append('dine_in', Number(this.storeDetails.dine_in))
      formData.append('book_a_table', Number(this.storeDetails.book_a_table))
      formData.append('misl_settings', JSON.stringify(this.misl_settings))
      formData.append('pickup', Number(this.storeDetails.pickup))
      formData.append('delivery', Number(this.storeDetails.delivery))

      const { updateStoreProfile } = useStoreUi()
      showLoader()
      updateStoreProfile({
        query: this.storeInfo.id,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.getStoreInfo()
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
.b-form-timepicker .b-time-footer {
  display: block !important;
  position: static !important;
}
</style>
