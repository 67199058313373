<template>
  <b-form class="provider-tab-social">
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-facebook.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="storeDetails.facebook"
                name="facebook"
                type="text"
                :placeholder="$t('Facebook (Your Business Facebook Link)')"
              />
            </div>
            <div>
              <b-form-checkbox
                switch
                inline
                :checked="storeDetails.facebook != '' && storeDetails.facebook != null"
                :disabled="storeDetails.facebook == '' || storeDetails.facebook == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-whatsapp.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="storeDetails.whatsapp"
                name="whatsapp"
                type="text"
                :placeholder="$t('Whatsapp (Your Business Whatsapp Link)')"
              />
            </div>
            <div>
              <b-form-checkbox
                switch
                inline
                :checked="storeDetails.whatsapp != '' && storeDetails.whatsapp != null"
                :disabled="storeDetails.whatsapp == '' || storeDetails.whatsapp == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-instagram.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="storeDetails.instagram"
                name="instagram"
                type="text"
                :placeholder="$t('Instagram (Your Business Instagram Link)')"
              />
            </div>
            <div>
              <b-form-checkbox
                switch
                inline
                :checked="storeDetails.instagram != '' && storeDetails.instagram != null"
                :disabled="storeDetails.instagram == '' || storeDetails.instagram == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-telegram-app.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="storeDetails.telegram"
                name="telegram"
                type="text"
                :placeholder="$t('Telegram (Your Business Telegram Link)')"
              />
            </div>
            <div>
              <b-form-checkbox
                switch
                inline
                :checked="storeDetails.telegram != '' && storeDetails.telegram != null"
                :disabled="storeDetails.telegram == '' || storeDetails.telegram == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-linkedin.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="storeDetails.linkedin"
                name="linkedin"
                type="text"
                :placeholder="$t('Linkedin (Your Business Linkedin Link)')"
              />
            </div>
            <div>
              <b-form-checkbox
                switch
                inline
                :checked="storeDetails.linkedin != '' && storeDetails.linkedin != null"
                :disabled="storeDetails.linkedin == '' || storeDetails.linkedin == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-twitter.png')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="storeDetails.twitter"
                name="twitter"
                type="text"
                :placeholder="$t('Twitter (Your Business Twitter Link)')"
              />
            </div>
            <div>
              <b-form-checkbox
                switch
                inline
                :checked="storeDetails.twitter != '' && storeDetails.twitter != null"
                :disabled="storeDetails.twitter == '' || storeDetails.twitter == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>


      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-facebook-messenger.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="storeDetails.messenger"
                name="messenger"
                type="text"
                :placeholder="$t('Messenger (Your Business Messenger Link)')"
              />
            </div>
            <div>
              <b-form-checkbox
                switch
                inline
                :checked="storeDetails.messenger != '' && storeDetails.messenger != null"
                :disabled="storeDetails.messenger == '' || storeDetails.messenger == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>


      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-tiktok.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="storeDetails.tiktok"
                name="tiktok"
                type="text"
                :placeholder="$t('TikTok (Your Business TikTok Link)')"
              />
            </div>
            <div>
              <b-form-checkbox
                switch
                inline
                :checked="storeDetails.tiktok != '' && storeDetails.tiktok != null"
                :disabled="storeDetails.tiktok == '' || storeDetails.tiktok == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>


      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-viber.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="storeDetails.viber"
                name="viber"
                type="text"
                :placeholder="$t('Viber (Your Business Viber Link)')"
              />
            </div>
            <div>
              <b-form-checkbox
                switch
                inline
                :checked="storeDetails.viber != '' && storeDetails.viber != null"
                :disabled="storeDetails.viber == '' || storeDetails.viber == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>


      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-youtube.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="storeDetails.youtube"
                name="youtube"
                type="text"
                :placeholder="$t('YouTube (Your Business YouTube Link)')"
              />
            </div>
            <div>
              <b-form-checkbox
                switch
                inline
                :checked="storeDetails.youtube != '' && storeDetails.youtube != null"
                :disabled="storeDetails.youtube == '' || storeDetails.youtube == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>


      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-pinterest.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="storeDetails.pintrest"
                name="pintrest"
                type="text"
                :placeholder="$t('Pinterest (Your Business Pinterest Link)')"
              />
            </div>
            <div>
              <b-form-checkbox
                switch
                inline
                :checked="storeDetails.pintrest != '' && storeDetails.pintrest != null"
                :disabled="storeDetails.pintrest == '' || storeDetails.pintrest == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>


      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-skype.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="storeDetails.skype"
                name="skype"
                type="text"
                :placeholder="$t('Skype (Your Business Skype Link)')"
              />
            </div>
            <div>
              <b-form-checkbox
                switch
                inline
                :checked="storeDetails.skype != '' && storeDetails.skype != null"
                :disabled="storeDetails.skype == '' || storeDetails.skype == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="12"
        class="my-auto text-center"
      >
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$emit('save-details')"
        >
          {{ $t('Save Changes') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox
  },
  props: {
    storeDetails: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style></style>
